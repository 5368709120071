import axios from 'axios'
import { getItem, removeItem } from '../../utils/storage.js';
import {logo} from "@/assets/logo.js";    

// let token = getItem('token')
let url = ''
let avatarHeader = ''

export const getUserAvatar =async (token)=>{
    await axios({
        url:'/acl/user/avatar/load',
        method:'get',  //默认get方法，可以不写
        responseType:'arraybuffer',  //或者是blob
        headers:{
            'token':token
        }
    }).then(res=>{
        let imageType = res.headers['content-type'];   //获取图片类型
        if(imageType=='image/png' || imageType=='image/jpeg'){
            const blob = new Blob([res.data], { type: imageType })
            const imageUrl = (window.URL || window.webkitURL).createObjectURL(blob)
            blobToBase64(blob, (dataurl) => {
                let pdfBase64 = dataurl;
                // console.log("base64", pdfBase64);
                let avatarDetailed = document.getElementById('avatarDetailed')
                let avatarHeader = document.getElementById('avatarHeader')
                // console.log(pdfBase64);
                if(avatarDetailed){
                    avatarDetailed.src = pdfBase64
                }
                avatarHeader.src = pdfBase64

                url = pdfBase64
                // console.log(imageUrl);
                sessionStorage.setItem('avatar',url)

            });
            // url = imageUrl
        }else{
            url = logo
        }
    })
    sessionStorage.setItem('avatar',url)
    // avatarHeader = document.getElementById('avatarHeader')
    // avatarHeader.src = url
    return url
}

function blobToBase64(blob, callback) {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
        callback(e.target.result);
    };
    fileReader.readAsDataURL(blob);
}