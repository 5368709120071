<template>
    <div id="setup" class="q-pa-md ">
        <!-- 面包屑 -->
        <q-breadcrumbs>
            <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
            <q-breadcrumbs-el label="设置" icon="iconfont icon-guanli" />
        </q-breadcrumbs>
        <!-- 个人信息tab区域 -->
        <div class='setupbox' id="setupbox">
            <q-card class="my-card" flat bordered  style="margin-bottom: 10px;">
                <q-card-section>
                    <div class='tabbox' id='tabbox'>
                        <el-tabs tab-position="left" @tab-click='tabClick'>
                            <!-- 个人信息 -->
                            <el-tab-pane label="个人信息">
                                <span slot="label">
                                    <i class="el-icon-user-solid"></i> 个人信息
                                </span>
                                <div class='personalInformationBox row justify-center'>
                                    <!-- 个人信息 -->
                                    <div class='InformationBox col-12 col-md-6'>
                                        <h6 class="tit">个人信息</h6>
                                        <div>
                                            <div class="tit">昵称</div>
                                            <div>
                                                <q-input outlined dense v-model="personal.nickName" :rules="[required.nickName]"/>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tit">手机号</div>
                                            <div>
                                                <q-input outlined dense v-model="personal.phoneNumber" disable :rules="[required.kong]"/>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tit">用户类型</div>
                                            <div>
                                                <q-input outlined dense v-model="personal.userType" disable :rules="[required.kong]"/>
                                            </div>
                                        </div>
                                        <div class="updateBtn">
                                            <el-button type="primary" size="small" plain @click='saveUser'>更新信息</el-button>
                                        </div>
                                    </div>
                                    <!-- 头像 -->
                                    <div class='headPortraitBox col-12 col-md-auto'>
                                        <div class='tou'>
                                            <q-avatar size="150px">
                                                <img id="avatarDetailed" :src="url" icon="directions">
                                            </q-avatar>
                                        </div>
                                        <div class='changeHead'>
                                            <el-button icon="el-icon-upload2" size="small" plain @click='toggleShow'>更改头像</el-button>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <!-- 密码修改 -->
                            <el-tab-pane label="密码修改">
                                <span slot="label"><i class="el-icon-unlock"></i> 密码修改</span>

                                <div class='PasswordmodificationBox row justify-center'>
                                    <div class='InformationBox col-12 col-md-6'>
                                        <h6 class="tit">密码修改</h6>
                                        <q-form ref="myForm" class="q-gutter-md">
                                            <!-- 旧密码 -->
                                            <div>
                                                <div class="tit">旧密码</div>
                                                <div>
                                                    <q-input
                                                        :type="isPwd ? 'password' : 'text'"
                                                        :dense='true'
                                                        outlined
                                                        v-model="editpassword.oldPwd"
                                                        label="旧密码"
                                                        lazy-rules
                                                        :rules="[required.oldname]"
                                                    >
                                                            <template v-slot:append>
                                                                <q-icon
                                                                    :name="isPwd ? 'visibility_off' : 'visibility'"
                                                                    class="cursor-pointer"
                                                                    @click="isPwd = !isPwd"
                                                                />
                                                            </template>
                                                    </q-input>
                                                </div>
                                            </div>
                                            <!-- 新密码 -->
                                            <div>
                                                <div class="tit">新密码</div>
                                                <div>
                                                    <q-input :type="isPwd1 ? 'password' : 'text'" ref="input" :dense='true' outlined  v-model="editpassword.newPwd" label="新密码*" lazy-rules :rules="[required.editpwd,required.password]">
                                                        <template v-slot:append>
                                                            <q-icon
                                                                :name="isPwd1 ? 'visibility_off' : 'visibility'"
                                                                class="cursor-pointer"
                                                                @click="isPwd1 = !isPwd1"
                                                            />
                                                        </template>
                                                    </q-input>
                                                </div>
                                            </div>
                                            <!-- 确认密码 -->
                                            <div>
                                                <div class="tit">确认密码</div>
                                                <div>
                                                    <q-input :type="isPwd2 ? 'password' : 'text'" :dense='true' outlined  v-model="editpassword.newPwds" label="确认新密码*" lazy-rules :rules="[required.confirmpassword]">
                                                        <template v-slot:append>
                                                            <q-icon
                                                                :name="isPwd2 ? 'visibility_off' : 'visibility'"
                                                                class="cursor-pointer"
                                                                @click="isPwd2 = !isPwd2"
                                                            />
                                                        </template>
                                                    </q-input>
                                                </div>
                                            </div>

                                            <div>
                                                <q-btn label="修改密码" @click="edit"  color="primary"/>
                                                <q-btn label="重置" @click="chongzhi" type="reset"  color="primary" flat class="q-ml-sm" />
                                            </div>
                                        </q-form>
                                    </div>
                                    <!-- 头像 -->
                                    <div class='headPortraitBox col-12 col-md-auto'>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </q-card-section>
            </q-card>
        </div>

        <my-upload 
            field="avatarFile"
            @src-file-set='srcFileSet'
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-model="show"
            url="/acl/user/avatar/save"
            :params="params"
            :headers="headers"
            :langExt='langExt'
            :withCredentials='true'
            img-format="png"
        >
        </my-upload>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import { 
Editpassword,
userPersonal,
userSaveNickName,
} from '@/api/Login.js'
// import { dict } from '@/api/components/dict.js'; // 数据词典接口

import jwt from 'jsonwebtoken'
import { getItem, removeItem,setItem } from '../../utils/storage.js';
import { getUserAvatar } from '@/api/components/getAvatar.js'; // 获取权限
import { getoptions,optionget } from '@/api/components/checkcitys.js'; // 获取城市接口
import {logo} from "@/assets/logo.js";    

import myUpload from 'vue-image-crop-upload/upload-2.vue';
import { notifys } from '@/api/components/Notify.js'

export default {
    data() {
        return {
            personal:{
                nickName:'',
                phoneNumber:'',
                userType:''
            },
            url:sessionStorage.getItem('avatar') || '',

            show: false,
			params: {},
			headers: {
				token: getItem('token'),
			},
            langExt:{            
                hint: '点击，或拖动图片至此处',
                loading: '正在上传……',
                noSupported: '浏览器不支持该功能，请使用IE10以上或其他现在浏览器！',
                success: '上传成功',
                fail: '图片上传失败',
                preview: '头像预览',
                btn: {
                    off: '取消',
                    close: '关闭',
                    back: '上一步',
                    save: '保存'
                },
                error: {
                    onlyImg: '仅限图片格式',
                    outOfSize: '单文件大小不能超过 2MB',
                    lowestPx: '图片最低像素为（宽*高）：'
                }
            },
			

            // 修改密码信息
            editpassword:{
                newPwd: '',
                oldPwd:'',
                newPwds:''
            },
            isPwd: true, // 控制密码是否显示
            isPwd1: true, // 控制密码是否显示
            isPwd2: true, // 控制密码是否显示
            // 密码验证正则表达
            reg: new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/),
            // 密码规则
            required: {
                oldname: val => val.trim() && val.trim().length > 0 || '请输入密码',
                editpwd: val => val.trim() && val.trim().length > 0 || '请设置密码',
                password: val => val.trim().length >= 8 && val.trim().length <= 20 || '密码最少为8到20位字符',
                confirmpassword: val => val.trim()===this.$refs.input.value || '两次密码不一致',
                passwords: val => this.reg.test(val) || '密码必须为8~20位并且包含数字英文和特殊字符',
                nickName: val => val && val.length > 0 || '用户昵称不得为空',
            },
        }
    },
    created(){
        // this.getPersonal()
        // this.getdict() // 获取数据词典
    },
    mounted() {
        this.getAvatar() //获取头像
        this.getPersonal() //获取个人信息
    },
    computed:{
        // ...mapState([
        //     'userType', //用户类型
        // ]),
        userType() {
            return this.$store.state.userType
        }
    },
    watch:{
        userType(v, o){

            if (v.length != 0) {
                this.getPersonal()
            }
        }
    },
    components:{
        'my-upload': myUpload
    },
    methods:{

        // 刷新获取头像
        async getAvatar(){
            const token = getItem('token')
            let avatarHeader = document.getElementById('avatarHeader')
            let avatarDetailed = document.getElementById('avatarDetailed')
            const imageUrl =await getUserAvatar(token)
            // console.log(imageUrl);
            avatarHeader.src = imageUrl
            avatarDetailed.src = imageUrl
        },
        // 获取用户基本信息
        async getPersonal(){
            const token = getItem('token')
            const jktToken = jwt.decode(token)
            const { data: res } = await userPersonal()
            console.log(res);
            let Type = {}
            if(res.code===20000){
                this.personal.nickName = res.data.nickName == '' ? jktToken.sub :  res.data.nickName
                // 更新右上角下拉框昵称
                this.$store.commit('getuserNickName',this.personal.nickName)

                this.personal.phoneNumber = res.data.phoneNumber
                if(this.userType.length){
                    Type=optionget(this.userType,res.data.userType) // 支付状态
                    this.personal.userType = Type.value
                }
            }
        },
        // 更新用户基本信息
        async saveUser(){
            const { data: res } = await userSaveNickName({
                nickName: this.personal.nickName
            })
            console.log('修改基础信息',res);
            if(res.code===20000){
                notifys('positive',res.message)
                setItem("token", res.data.token);
                this.getPersonal()
            }else{
                notifys('negative',res.message)
            }
        },
        // 控制头像截取
        toggleShow() {
            this.show = !this.show;
        },
        // 图像选取完成
        srcFileSet(fileName,fileType,fileSize ){
            console.log('--------  图像选取完成 --------');
            console.log('fileName',fileName);
            console.log('fileType',fileType);
            console.log('fileSize',fileSize);
        },
        /**
         * crop success
         *
         * [param] imgDataUrl
         * [param] field
         */
        cropSuccess(imgDataUrl, field){
            console.log('-------- crop success --------');
            // console.log('imgDataUrl',imgDataUrl);
            console.log('field',field);
            let size = this.base64ImageSize(imgDataUrl)
            console.log(size);
            if(size>2){
                notifys('negative','头像图片不能超过2mb')
            }
        },
        /**
         * upload success
         *  头像上传成功
         * [param] jsonData   服务器返回数据，已进行json转码
         * [param] field
         */
        cropUploadSuccess(jsonData, field){
            console.log('-------- upload success --------');
            console.log(jsonData);
            console.log('field: ' + field);
            this.getAvatar()
        },
        /**
         * upload fail
         *  头像上传失败
         * [param] status    server api return error status, like 500
         * [param] field
         */
        cropUploadFail(status, field){
            console.log('-------- upload fail --------');
            console.log(status);
            console.log('field: ' + field);
            this.getAvatar()
        },

        // 获取base64图片大小 获取到的数值为MB
        base64ImageSize(base64ImageStr) {
            const indexBase64 = base64ImageStr.indexOf('base64,');
            // console.log(indexBase64);
            if (indexBase64 < 0) return -1;
            const str = base64ImageStr.substr(indexBase64 + 6);
            return (str.length * 0.75 / 1024 / 1024).toFixed(2);
        },

        base64toFile(dataurl, filename) { //将base64转换为文件
            var arr = dataurl.split(','), 
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        },
        // tab切换时
        tabClick(v){
            // console.log(v);
            if(v.label== '个人信息'){
                this.getAvatar() //获取头像
                this.getPersonal() //获取个人信息
            }else{
                // 清空密码修改的信息
                this.editpassword.newPwd=''
                this.editpassword.oldPwd=''
                this.editpassword.newPwds=''
                this.$refs.myForm.resetValidation()
            }
        },
        // 修改密码
        edit(){
            this.$refs.myForm.validate().then( async outcome =>{
                if(outcome){
                    try{
                        const { data:res } =  await Editpassword({
                            newPwd: this.editpassword.newPwd,
                            oldPwd: this.editpassword.oldPwd
                        })
                        console.log(res);
                        if(res.code===20000){
                            notifys('positive',res.message)
                            // this.getMunsList()
                            this.editpassword.newPwd=''
                            this.editpassword.oldPwd=''
                            this.editpassword.newPwds=''
                            this.$refs.myForm.resetValidation()
                            // this.right = false
                        }else{
                            notifys('negative',res.message)
                        }
                    }catch (e){
                        console.log(e);
                    }
                }
            })
        },
        // 重置密码表单
        chongzhi(){
            this.editpassword.newPwd=''
            this.editpassword.oldPwd=''
            this.editpassword.newPwds=''
            this.$refs.myForm.resetValidation()
        },
    },
}
</script>
<style lang="less" scoped>
.setupbox{
    margin-top: 10px;
    .personalInformationBox{
        // display: flex;
        width: 100%;
        padding: 5px 0 15px;
        .InformationBox{
            padding-right: 15px;
            .tit{
                margin: 5px 0;
            }
            .updateBtn{
                margin: 15px 0 0 0;
                text-align: center;
            }
        }
        .headPortraitBox{
            min-width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .tou{
                text-align: center;
                margin: 15px 0;
            }
        }
    }

    .PasswordmodificationBox{
        // display: flex;
        width: 100%;
        padding: 5px 0 15px;
        .InformationBox{
            padding-right: 15px;
            .tit{
                margin: 5px 0;
            }
        }
        .headPortraitBox{
            min-width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .tou{
                text-align: center;
                margin: 15px 0;
            }
        }
    }
}
</style>
